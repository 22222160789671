export default function useAccommodation() {
  const roomIcons = [
    {
      name: "singleRoom1",
      icon: require("@/assets/roomIcons/singleRoom1.png"),
    },
    {
      name: "doubleRoom1",
      icon: require("@/assets/roomIcons/doubleRoom1.png"),
    },
    {
      name: "doubleRoomSingle1",
      icon: require("@/assets/roomIcons/doubleRoomSingle1.png"),
    },
    {
      name: "singleRoom2",
      icon: require("@/assets/roomIcons/singleRoom2.png"),
    },
    {
      name: "doubleRoom2",
      icon: require("@/assets/roomIcons/doubleRoom2.png"),
    },
    {
      name: "doubleRoomSplit2",
      icon: require("@/assets/roomIcons/doubleRoomSplit2.png"),
    },
    {
      name: "doubleRoomSingle2",
      icon: require("@/assets/roomIcons/doubleRoomSingle2.png"),
    },
    {
      name: "singleRoom3",
      icon: require("@/assets/roomIcons/singleRoom3.png"),
    },
    {
      name: "doubleRoom3",
      icon: require("@/assets/roomIcons/doubleRoom3.png"),
    },
    {
      name: "doubleRoomSplit3",
      icon: require("@/assets/roomIcons/doubleRoomSplit3.png"),
    },
    {
      name: "doubleRoomSingle3",
      icon: require("@/assets/roomIcons/doubleRoomSingle3.png"),
    },
  ];

  return {
    roomIcons,
  };
}
